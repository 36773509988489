import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  Sun, Moon, Copy, Hexagon, ExternalLink
} from 'lucide-react';

// Floating Emojis Component
const FloatingEmojis = () => {
  const [emojis, setEmojis] = useState([]);
  
  useEffect(() => {
    const createEmoji = () => {
      const id = Math.random();
      const startX = Math.random() * window.innerWidth;
      const rotation = Math.random() * 360;
      const duration = 7 + Math.random() * 3;
      
      return {
        id,
        startX,
        rotation,
        duration,
      };
    };

    const addEmoji = () => {
      setEmojis(current => {
        const newEmojis = [...current, createEmoji()];
        if (newEmojis.length > 15) {
          return newEmojis.slice(-15);
        }
        return newEmojis;
      });
    };

    const interval = setInterval(addEmoji, 1500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden z-10">
      <AnimatePresence>
        {emojis.map(({ id, startX, rotation, duration }) => (
          <motion.div
            key={id}
            initial={{
              opacity: 0.15,
              x: startX,
              y: window.innerHeight + 50,
              rotate: 0,
            }}
            animate={{
              y: -100,
              rotate: rotation,
            }}
            exit={{ opacity: 0 }}
            transition={{
              duration: duration,
              ease: "linear",
            }}
            className="absolute text-3xl"
          >
            🍯
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

// Hexagon Frame Component
const HexagonFrame = () => (
  <div className="relative">
    <svg viewBox="0 0 100 100" className="w-72 h-72 mx-auto">
      <defs>
        <clipPath id="hexagonClip">
          <path d="M50 0 L93.3 25 V75 L50 100 L6.7 75 V25 Z" />
        </clipPath>
        <linearGradient id="hexBorder" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor="#FCD34D" />
          <stop offset="100%" stopColor="#F59E0B" />
        </linearGradient>
      </defs>
      
      <path 
        d="M50 0 L93.3 25 V75 L50 100 L6.7 75 V25 Z" 
        fill="white"
      />
      
      <foreignObject x="0" y="0" width="100" height="100" clipPath="url(#hexagonClip)">
        <div className="w-full h-full">
          <img 
            src="/honeypot.png"
            alt="Honeypot Token"
            className="w-full h-full object-contain"
          />
        </div>
      </foreignObject>
      
      <path 
        d="M50 0 L93.3 25 V75 L50 100 L6.7 75 V25 Z" 
        fill="none"
        stroke="url(#hexBorder)"
        strokeWidth="2"
      />
    </svg>
  </div>
);

// Background Hexagon Pattern Component
const HexagonBackground = () => (
  <svg width="100%" height="100%" className="absolute inset-0 z-0 opacity-10">
    <pattern id="hexagons" width="50" height="43.4" patternUnits="userSpaceOnUse" patternTransform="scale(2)">
      <path 
        d="M25,0 L50,14.433333333333334 L50,43.3 L25,57.733333333333334 L0,43.3 L0,14.433333333333334 Z" 
        fill="none" 
        stroke="currentColor" 
        strokeWidth="1"
      />
    </pattern>
    <rect width="100%" height="100%" fill="url(#hexagons)" />
  </svg>
);

const Honeypot = () => {
  const [isDark, setIsDark] = useState(true);
  const [copied, setCopied] = useState(false);
  const CONTRACT_ADDRESS = '0x390Aca3f39fa427F31cAdB78adbA4C4e88f9171F';

  const copyAddress = () => {
    navigator.clipboard.writeText(CONTRACT_ADDRESS);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className={`min-h-screen font-['Rajdhani'] ${
      isDark 
        ? 'bg-gradient-to-b from-black via-gray-900 to-black text-white' 
        : 'bg-gradient-to-b from-amber-50 via-white to-amber-50 text-gray-900'
    } transition-all duration-500 relative overflow-hidden`}>
      
      <FloatingEmojis />
      
      <link href="https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap" rel="stylesheet" />
      
      <div className={`${isDark ? 'text-amber-400' : 'text-amber-600'}`}>
        <HexagonBackground />
      </div>

      {/* Navigation */}
      <nav className="fixed w-full z-50 backdrop-blur-md bg-opacity-70">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            className="text-2xl font-bold flex items-center gap-2"
          >
            <span className={`${isDark ? 'text-amber-400' : 'text-amber-600'}`}>
              🍯 Honeypot <span className="text-sm">$HONEY</span>
            </span>
          </motion.div>
          
          <div className="flex items-center gap-4">
            <Link
              to="/detector"
              className="text-amber-400 hover:text-amber-300 transition-colors"
            >
              Honeypot Detector
            </Link>
            <Link
              to="/game"
              className="text-amber-400 hover:text-amber-300 transition-colors"
            >
              Play Game
            </Link>
            <button
              onClick={() => setIsDark(!isDark)}
              className="p-2 rounded-full hover:bg-amber-400/20 transition-colors"
            >
              {isDark ? 
                <Sun size={24} className="text-amber-400" /> : 
                <Moon size={24} className="text-amber-600" />
              }
            </button>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="relative z-10">
        {/* Hero Image Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="container mx-auto pt-24 pb-12 px-6 text-center"
        >
          <HexagonFrame />
        </motion.div>

        {/* Hero Content */}
        <section className="container mx-auto px-6 text-center">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-5xl md:text-7xl font-bold mb-6"
          >
            <span className={isDark ? 'text-amber-400' : 'text-amber-600'}>
              A Meme wif a mission
            </span>
          </motion.h1>

          {/* Contract Card */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className={`max-w-2xl mx-auto p-6 rounded-xl ${
              isDark ? 'bg-gray-800/50' : 'bg-white/50'
            } backdrop-blur-lg shadow-xl border-2 ${
              isDark ? 'border-amber-400/20' : 'border-amber-600/20'
            } mb-8`}
          >
            <div className="flex items-center justify-center gap-4 mb-4">
              <Hexagon className={isDark ? 'text-amber-400' : 'text-amber-600'} />
              <h3 className="font-semibold">Verified Contract</h3>
            </div>
            <div className="flex items-center justify-center gap-2 break-all mb-6">
              <code className={isDark ? 'text-amber-400' : 'text-amber-600'}>
                {CONTRACT_ADDRESS}
              </code>
              <button
                onClick={copyAddress}
                className="p-2 hover:bg-amber-400/20 rounded-full transition-colors relative"
              >
                <Copy size={20} />
                {copied && (
                  <motion.span
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    className="absolute text-sm text-green-400 left-0 top-full mt-1"
                  >
                    Copied!
                  </motion.span>
                )}
              </button>
            </div>

            {/* CTA Buttons */}
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <motion.a
                href="https://app.uniswap.org/swap?outputCurrency=0x390Aca3f39fa427F31cAdB78adbA4C4e88f9171F&chain=base"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                className={`${
                  isDark ? 'bg-amber-400 text-gray-900' : 'bg-amber-600 text-white'
                } px-8 py-4 rounded-full text-lg font-bold transition-all duration-200`}
              >
                Buy $HONEY Now <ExternalLink className="inline-block ml-2" size={16} />
              </motion.a>
              
              <motion.a
                href="https://warpcast.com/~/channel/honeymoon"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.05 }}
                className={`border-2 ${
                  isDark 
                    ? 'border-amber-400 text-amber-400 hover:bg-amber-400 hover:text-gray-900' 
                    : 'border-amber-600 text-amber-600 hover:bg-amber-600 hover:text-white'
                } px-8 py-4 rounded-full text-lg font-bold transition-all duration-200`}
              >
                Join honeymoon.army! <ExternalLink className="inline-block ml-2" size={16} />
              </motion.a>
            </div>
          </motion.div>
        </section>

        {/* Features Grid */}
        <section className="container mx-auto px-6 py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: "🎯",
                title: "Based Dev",
                description: "Mission: Profit from this GOOD Honeypot? Keep your eyes out for those who get snared in EVIL honeypots… & share some $Honey with them!"
              },
              {
                icon: "💰",
                title: "Tokenomics",
                description: "Click to learn more.",
                link: "#tokenomics"
              },
              {
                icon: "🔎",
                title: "Honeypot Spot",
                description: "Click to learn more.",
                link: "#honeypot-spot"
              },
              {
                icon: "🛡️",
                title: "Sweet Protection",
                description: "We protect our frens from bad actors."
              }
            ].map((feature, index) => (
              <motion.div
                key={feature.title}
                className={`p-6 rounded-xl ${
                  isDark ? 'bg-gray-800/50' : 'bg-white/50'
                } backdrop-blur-lg shadow-xl border ${
                  isDark ? 'border-amber-400/20' : 'border-amber-600/20'
                } ${feature.link ? 'cursor-pointer' : ''}`}
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 + index * 0.1 }}
                whileHover={{ y: -5 }}
                onClick={() => {
                  if (feature.link) {
                    const element = document.querySelector(feature.link);
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }
                }}
              >
                <span className="text-4xl mb-4 block">{feature.icon}</span>
                <h3 className="text-xl font-bold mb-2">
                  {feature.title}
                </h3>
                <p className={isDark ? 'text-gray-300' : 'text-gray-600'}>
                  {feature.description}
                </p>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Tokenomics Section */}
        <section id="tokenomics" className="container mx-auto px-6 py-12">
          <h2 className="text-3xl font-bold mb-6 text-center">
            Tokenomics
          </h2>
          <div className={`max-w-2xl mx-auto p-6 rounded-xl ${
            isDark ? 'bg-gray-800/50' : 'bg-white/50'
          } backdrop-blur-lg shadow-xl border-2 ${
            isDark ? 'border-amber-400/20' : 'border-amber-600/20'
          } mb-8`}>
            <ul className={`${isDark ? 'text-gray-300' : 'text-gray-600'} list-disc list-inside`}>
              <li>100% direct public sale.</li>
              <li>Total Supply: 1 Billion</li>
            </ul>
            <div className="mt-6 flex items-center justify-center gap-6">
              <a 
href="https://dexscreener.com/base/0x43fb3584c2da5ac5ebcf59cdf51bab9b7b77e6af"
target="_blank"
rel="noopener noreferrer"
className="transition-transform hover:scale-105"
>
<img 
  src="/dexscreener.jpg" 
  alt="Dexscreener" 
  className="w-[100px] h-auto object-contain hover:outline hover:outline-2 hover:outline-amber-500"
/>
</a>
<a 
href="https://basescan.org/token/0x390Aca3f39fa427F31cAdB78adbA4C4e88f9171F"
target="_blank"
rel="noopener noreferrer"
className="transition-transform hover:scale-105"
>
<img 
  src="/basescan.png" 
  alt="Basescan" 
  className="w-[100px] h-auto object-contain hover:outline hover:outline-2 hover:outline-amber-500"
/>
</a>
<a 
href="https://www.clanker.world/clanker/0x390Aca3f39fa427F31cAdB78adbA4C4e88f9171F"
target="_blank"
rel="noopener noreferrer"
className="transition-transform hover:scale-105"
>
<img 
  src="/clanker.png" 
  alt="Clanker" 
  className="w-[100px] h-auto object-contain hover:outline hover:outline-2 hover:outline-amber-500"
/>
</a>
</div>
</div>
</section>

{/* Honeypot Spot Section */}
<section id="honeypot-spot" className="container mx-auto px-6 py-12">
<h2 className="text-3xl font-bold mb-6 text-center">
Honeypot Spot
</h2>
<div className={`max-w-3xl mx-auto p-6 rounded-xl ${
isDark ? 'bg-gray-800/50' : 'bg-white/50'
} backdrop-blur-lg shadow-xl border-2 ${
isDark ? 'border-amber-400/20' : 'border-amber-600/20'
} mb-8 text-left`}>
<p className={`${isDark ? 'text-gray-300' : 'text-gray-600'} mb-4`}>
Honeypot scams are malicious traps set by bad actors in the crypto space. They lure unsuspecting investors by creating tokens that appear legitimate but are designed to prevent buyers from selling their tokens, effectively locking their funds. Here are some tips to help you spot and avoid honeypot scams:
</p>
<ul className={`${isDark ? 'text-gray-300' : 'text-gray-600'} list-disc list-inside space-y-2`}>
<li>
<strong>Check the Smart Contract Code:</strong> Before investing, review the token's smart contract code on platforms like Etherscan. Look for any suspicious functions that restrict selling or transfer of tokens.
</li>
<li>
<strong>Use Honeypot Detection Tools:</strong> Utilize online tools and services that automatically analyze smart contracts for honeypot characteristics. These tools can provide quick insights into potential risks.
</li>
<li>
<strong>Analyze Liquidity Pool:</strong> A legitimate token should have a healthy liquidity pool. If the liquidity is low or locked in a way that prevents withdrawals, it could be a red flag.
</li>
<li>
<strong>Verify Contract Ownership:</strong> If the contract owner has the ability to change fees or control transactions, this could be used maliciously. Ensure that the contract ownership is renounced or locked appropriately.
</li>
<li>
<strong>Check Transaction Fees:</strong> Be cautious of tokens with extremely high transaction fees, especially on selling. This could be a mechanism to deter users from selling their tokens.
</li>
<li>
<strong>Research Team & Community:</strong> Active and transparent communication from developers and a supportive community are good signs.
</li>
<li>
<strong>Watch for Red Flags:</strong> If the token has functions like "blacklist" or "disableTrading," it may be used to prevent users from selling.
</li>
<li>
<strong>Look for Audits:</strong> Legitimate projects often undergo security audits by reputable firms. Lack of audits can be a warning sign.
</li>
</ul>
<div className="mt-6 p-4 bg-amber-400/10 rounded-lg border border-amber-400/20">
<p className={`${isDark ? 'text-gray-300' : 'text-gray-600'} font-medium`}>
<strong>Stay SAFU:</strong> Always do your own research (DYOR) before investing in any cryptocurrency. The DeFi space is full of opportunities but also risks. Being cautious and informed can help you avoid falling victim to honeypot scams.
</p>
<Link
to="/detector"
className="inline-flex items-center gap-2 mt-4 text-amber-400 hover:text-amber-300 transition-colors"
>
Try our Honeypot Detector <ExternalLink size={16} />
</Link>
</div>
</div>
</section>

{/* Footer */}
<footer className={`py-12 mt-12 ${isDark ? 'bg-gray-900/50' : 'bg-amber-50/50'}`}>
<div className="container mx-auto px-6">
<div className="max-w-4xl mx-auto text-center">
<h2 className={`text-2xl font-bold mb-4 ${isDark ? 'text-amber-400' : 'text-amber-600'}`}>
Join the Honeymoon Army 🍯
</h2>
<p className={`${isDark ? 'text-gray-300' : 'text-gray-600'} mb-6`}>
Together we can make DeFi safer and sweeter for everyone.
</p>
<div className="flex flex-wrap justify-center gap-4">
<motion.a
  href="https://warpcast.com/~/channel/honeymoon"
  target="_blank"
  rel="noopener noreferrer"
  whileHover={{ scale: 1.05 }}
  className={`inline-flex items-center gap-2 px-6 py-3 rounded-full
    ${isDark ? 'bg-amber-400 text-gray-900' : 'bg-amber-600 text-white'}`}
>
  Join Community <ExternalLink size={16} />
</motion.a>
<motion.a
  href="https://app.uniswap.org/swap?outputCurrency=0x390Aca3f39fa427F31cAdB78adbA4C4e88f9171F&chain=base"
  target="_blank"
  rel="noopener noreferrer"
  whileHover={{ scale: 1.05 }}
  className={`inline-flex items-center gap-2 px-6 py-3 rounded-full
    border-2 ${isDark 
      ? 'border-amber-400 text-amber-400 hover:bg-amber-400 hover:text-gray-900' 
      : 'border-amber-600 text-amber-600 hover:bg-amber-600 hover:text-white'}`}
>
  Buy $HONEY <ExternalLink size={16} />
</motion.a>
</div>
<p className={`mt-8 text-sm ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
&copy; {new Date().getFullYear()} 🍯 Honeypot $HONEY. All rights reserved.
</p>
</div>
</div>
</footer>
</div>
</div>
);
};

export default Honeypot;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Sun, Moon, ExternalLink,
  Search, AlertTriangle, Loader2, Shield,
  ArrowLeft, Download,
  FileJson, FileText, X, Info
} from 'lucide-react';
import jsPDF from 'jspdf';

const HONEYPOT_API_URL = 'https://api.honeypot.is/v2';
const CHAIN_IDS = {
  ETHEREUM: 1,
  BASE: 8453
};

// Formatting Utilities
const formatNumber = (num) => {
  if (num === undefined || num === null) return 'N/A';
  return new Intl.NumberFormat().format(num);
};

const formatPercentage = (num) => {
  if (num === undefined || num === null) return 'N/A';
  return `${num.toFixed(2)}%`;
};

const formatGas = (gas) => {
  if (!gas) return 'N/A';
  const formatted = formatNumber(gas);
  return `${formatted} gas units`;
};

const formatUSD = (num) => {
  if (!num) return 'N/A';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(num);
};

const getGasRiskLevel = (gas) => {
  if (!gas) return { risk: 'unknown', note: 'Gas cost data unavailable' };
  if (gas > 1000000) return { risk: 'high', note: 'Extremely high gas cost indicates complex or suspicious contract logic' };
  if (gas > 500000) return { risk: 'medium', note: 'Higher than normal gas cost - verify contract functionality' };
  if (gas > 250000) return { risk: 'low', note: 'Normal gas cost range for token transactions' };
  return { risk: 'safe', note: 'Standard gas cost for simple transactions' };
};

const getTaxRiskLevel = (tax) => {
  if (tax === undefined || tax === null) return { risk: 'unknown', note: 'Tax data unavailable' };
  if (tax > 20) return { risk: 'high', note: 'Very high tax rate - potential red flag' };
  if (tax > 10) return { risk: 'medium', note: 'Higher than average tax rate' };
  if (tax > 5) return { risk: 'low', note: 'Moderate tax rate within normal range' };
  return { risk: 'safe', note: 'Standard or low tax rate' };
};

const analyzeMetrics = (results) => {
  if (!results) return null;

  // Map and analyze all available data
  const metrics = {
    // Existing sections
    contractInfo: {
      name: {
        label: 'Token Name',
        value: results.token?.name || 'N/A',
        type: 'info',
        note: results.token?.name ? 'Token name verified' : 'Token name not available'
      },
      symbol: {
        label: 'Symbol',
        value: results.token?.symbol || 'N/A',
        type: 'info',
        note: results.token?.symbol ? 'Token symbol verified' : 'Symbol not available'
      },
      decimals: {
        label: 'Decimals',
        value: results.token?.decimals?.toString() || 'N/A',
        type: 'info',
        note: `Standard token decimals: ${results.token?.decimals === 18 ? 'Yes' : 'No'}`
      },
      address: {
        label: 'Token Address',
        value: results.token?.address || 'N/A',
        type: 'info',
        note: 'Unique contract address of the token'
      },
      totalHolders: {
        label: 'Total Holders',
        value: formatNumber(results.token?.totalHolders),
        type: 'info',
        note: 'Number of unique addresses holding the token',
      },
    },
    transactionCosts: {
      buyGas: {
        label: 'Buy Transaction Gas',
        value: formatGas(results.simulationResult?.buyGas),
        ...getGasRiskLevel(results.simulationResult?.buyGas)
      },
      sellGas: {
        label: 'Sell Transaction Gas',
        value: formatGas(results.simulationResult?.sellGas),
        ...getGasRiskLevel(results.simulationResult?.sellGas)
      },
      buyTax: {
        label: 'Buy Tax',
        value: formatPercentage(results.simulationResult?.buyTax),
        ...getTaxRiskLevel(results.simulationResult?.buyTax)
      },
      sellTax: {
        label: 'Sell Tax',
        value: formatPercentage(results.simulationResult?.sellTax),
        ...getTaxRiskLevel(results.simulationResult?.sellTax)
      },
      transferTax: {
        label: 'Transfer Tax',
        value: formatPercentage(results.simulationResult?.transferTax),
        ...getTaxRiskLevel(results.simulationResult?.transferTax),
        note: 'Tax applied when transferring tokens between wallets',
      },
    },
    tradingLimits: {
      maxTxAmount: {
        label: 'Max Transaction',
        value: results.maxTxAmount ? formatNumber(results.maxTxAmount) + ' tokens' : 'No limit',
        type: results.maxTxAmount ? 'warning' : 'success',
        note: results.maxTxAmount ? 'Transaction amount is restricted' : 'No transaction amount limits'
      },
      maxWallet: {
        label: 'Max Wallet Size',
        value: results.maxWallet ? formatNumber(results.maxWallet) + ' tokens' : 'No limit',
        type: results.maxWallet ? 'warning' : 'success',
        note: results.maxWallet ? 'Wallet size is restricted' : 'No wallet size restrictions'
      }
    },
    contractSafety: {
      isVerified: {
        label: 'Contract Verified',
        value: results.contractCode?.openSource ? 'Yes' : 'No',
        type: results.contractCode?.openSource ? 'success' : 'error',
        note: results.contractCode?.openSource ?
          'Contract source code is verified and readable' :
          'Contract source code is not verified - high risk'
      },
      isProxy: {
        label: 'Proxy Contract',
        value: results.contractCode?.isProxy ? 'Yes' : 'No',
        type: results.contractCode?.isProxy ? 'warning' : 'success',
        note: results.contractCode?.isProxy ?
          'Contract can be upgraded - verify owner permissions' :
          'Contract is not upgradeable'
      },
      hasProxyCalls: {
        label: 'Has Proxy Calls',
        value: results.contractCode?.hasProxyCalls ? 'Yes' : 'No',
        type: results.contractCode?.hasProxyCalls ? 'warning' : 'success',
        note: results.contractCode?.hasProxyCalls ?
          'Contract makes proxy calls' :
          'No proxy calls detected in contract'
      },
      rootOpenSource: {
        label: 'Root Open Source',
        value: results.contractCode?.rootOpenSource ? 'Yes' : 'No',
        type: results.contractCode?.rootOpenSource ? 'success' : 'warning',
        note: results.contractCode?.rootOpenSource ?
          'Root contract source code is open source' :
          'Root contract source code is not open source'
      },
    },

    // New sections
    holderAnalysis: {
      totalHolders: {
        label: 'Total Holders',
        value: formatNumber(results.token?.totalHolders),
        type: 'info',
        note: 'Number of unique addresses holding the token',
      },
      successfulTransfers: {
        label: 'Successful Transfers',
        value: formatNumber(results.holderAnalysis?.successful),
        type: 'success',
        note: 'Number of successful transfers during analysis',
      },
      failedTransfers: {
        label: 'Failed Transfers',
        value: formatNumber(results.holderAnalysis?.failed),
        type: results.holderAnalysis?.failed > 0 ? 'warning' : 'success',
        note: 'Number of failed transfers during analysis',
      },
      averageGas: {
        label: 'Average Gas Used',
        value: formatGas(results.holderAnalysis?.averageGas),
        type: 'info',
        note: 'Average gas used in transfers',
      },
      averageTax: {
        label: 'Average Tax',
        value: formatPercentage(results.holderAnalysis?.averageTax),
        ...getTaxRiskLevel(results.holderAnalysis?.averageTax),
        note: 'Average tax rate in transfers',
      },
      highestTax: {
        label: 'Highest Tax',
        value: formatPercentage(results.holderAnalysis?.highestTax),
        ...getTaxRiskLevel(results.holderAnalysis?.highestTax),
        note: 'Highest tax rate encountered',
      },
      highTaxWallets: {
        label: 'High Tax Wallets',
        value: formatNumber(results.holderAnalysis?.highTaxWallets),
        type: results.holderAnalysis?.highTaxWallets > 0 ? 'warning' : 'success',
        note: 'Number of wallets experiencing high taxes',
      },
      siphoned: {
        label: 'Siphoned Amount',
        value: formatNumber(results.holderAnalysis?.siphoned),
        type: results.holderAnalysis?.siphoned > 0 ? 'error' : 'success',
        note: 'Total amount siphoned from holders',
      },
    },

    liquidityInfo: {
      liquidity: {
        label: 'Liquidity (USD)',
        value: formatUSD(results.pair?.liquidity),
        type: 'info',
        note: 'Total liquidity in the pool',
      },
      reservesToken: {
        label: `Reserves (${results.token?.symbol || 'Token'})`,
        value: formatNumber(results.pair?.reserves0),
        type: 'info',
        note: 'Reserve amount of the token',
      },
      reservesPairToken: {
        label: `Reserves (${results.withToken?.symbol || 'Pair Token'})`,
        value: formatNumber(results.pair?.reserves1),
        type: 'info',
        note: 'Reserve amount of the pair token (e.g., WETH)',
      },
      createdAt: {
        label: 'Pair Created At',
        value: results.pair?.createdAtTimestamp
          ? new Date(parseInt(results.pair?.createdAtTimestamp) * 1000).toLocaleString()
          : 'N/A',
        type: 'info',
        note: 'Timestamp when the liquidity pair was created',
      },
      pairAddress: {
        label: 'Pair Address',
        value: results.pair?.pair?.address || 'N/A',
        type: 'info',
        note: 'Address of the liquidity pair contract',
      },
      routerAddress: {
        label: 'Router Address',
        value: results.router || 'N/A',
        type: 'info',
        note: 'Address of the router used for swaps',
      },
    },

    contractCodeAnalysis: {
      openSource: {
        label: 'Open Source',
        value: results.contractCode?.openSource ? 'Yes' : 'No',
        type: results.contractCode?.openSource ? 'success' : 'error',
        note: results.contractCode?.openSource
          ? 'Contract code is open source'
          : 'Contract code is not open source',
      },
      isProxy: {
        label: 'Is Proxy',
        value: results.contractCode?.isProxy ? 'Yes' : 'No',
        type: results.contractCode?.isProxy ? 'warning' : 'success',
        note: results.contractCode?.isProxy
          ? 'Contract is a proxy contract'
          : 'Contract is not a proxy contract',
      },
      hasProxyCalls: {
        label: 'Has Proxy Calls',
        value: results.contractCode?.hasProxyCalls ? 'Yes' : 'No',
        type: results.contractCode?.hasProxyCalls ? 'warning' : 'success',
        note: results.contractCode?.hasProxyCalls
          ? 'Contract makes proxy calls'
          : 'Contract does not make proxy calls',
      },
      rootOpenSource: {
        label: 'Root Open Source',
        value: results.contractCode?.rootOpenSource ? 'Yes' : 'No',
        type: results.contractCode?.rootOpenSource ? 'success' : 'warning',
        note: results.contractCode?.rootOpenSource
          ? 'Root contract code is open source'
          : 'Root contract code is not open source',
      },
    },
  };

  // Calculate comprehensive risk score
  let riskScore = 100;
  const riskFactors = [];
  const safetyChecks = [];

  // Contract verification risks
  if (!results.contractCode?.openSource) {
    riskScore -= 30;
    riskFactors.push({
      factor: 'Unverified Contract',
      impact: 'High Risk',
      explanation: 'Contract source code is not verified, making it impossible to audit the code'
    });
  } else {
    safetyChecks.push('Contract is verified and auditable');
  }

  // Gas costs analysis
  if (results.simulationResult?.buyGas > 1000000 || results.simulationResult?.sellGas > 1000000) {
    riskScore -= 20;
    riskFactors.push({
      factor: 'Extreme Gas Costs',
      impact: 'High Risk',
      explanation: 'Transaction gas costs are suspiciously high, indicating complex or malicious contract logic'
    });
  } else if (results.simulationResult?.buyGas > 500000 || results.simulationResult?.sellGas > 500000) {
    riskScore -= 10;
    riskFactors.push({
      factor: 'High Gas Costs',
      impact: 'Medium Risk',
      explanation: 'Transaction gas costs are higher than normal, suggesting complex contract operations'
    });
  } else if (results.simulationResult?.buyGas && results.simulationResult?.sellGas) {
    safetyChecks.push('Transaction gas costs are within normal ranges');
  }

  // Tax analysis
  if (results.simulationResult?.buyTax > 20 || results.simulationResult?.sellTax > 20) {
    riskScore -= 20;
    riskFactors.push({
      factor: 'Extreme Taxes',
      impact: 'High Risk',
      explanation: 'Very high transaction taxes may indicate a potential trap or unsustainable tokenomics'
    });
  } else if (results.simulationResult?.buyTax > 10 || results.simulationResult?.sellTax > 10) {
    riskScore -= 10;
    riskFactors.push({
      factor: 'High Taxes',
      impact: 'Medium Risk',
      explanation: 'Transaction taxes are higher than average, reducing profit potential'
    });
  } else if (results.simulationResult?.buyTax !== undefined && results.simulationResult?.sellTax !== undefined) {
    safetyChecks.push('Transaction taxes are within reasonable limits');
  }

  // Trading restrictions
  if (results.maxTxAmount || results.maxWallet) {
    riskScore -= 5;
    riskFactors.push({
      factor: 'Trading Limits',
      impact: 'Low Risk',
      explanation: 'Transaction or wallet size limits are in place, which may restrict trading'
    });
  } else {
    safetyChecks.push('No trading restrictions detected');
  }

  // Contract warnings
  if (results.flags && results.flags.length > 0) {
    const impact = results.flags.length * 5;
    riskScore -= impact;
    riskFactors.push({
      factor: 'Contract Warnings',
      impact: `${results.flags.length > 2 ? 'High' : 'Medium'} Risk`,
      explanation: 'Suspicious contract features detected:\n' + results.flags.join('\n')
    });
  } else {
    safetyChecks.push('No suspicious contract features detected');
  }

  // Honeypot status
  if (results.honeypotResult?.isHoneypot) {
    riskScore -= 50;
    riskFactors.push({
      factor: 'Honeypot Detected',
      impact: 'Critical Risk',
      explanation: 'Token shows clear signs of being a honeypot. DO NOT TRADE.'
    });
  }

  riskScore = Math.max(0, Math.min(100, riskScore));

  return {
    metrics,
    riskScore,
    riskLevel: riskScore > 75 ? 'Low' : riskScore > 50 ? 'Medium' : 'High',
    riskFactors,
    safetyChecks,
    summary: riskScore > 75
      ? 'Token shows normal characteristics but always conduct your own research'
      : riskScore > 50
        ? 'Token shows some concerning characteristics - careful review recommended'
        : 'Token shows multiple high-risk indicators - trading is not recommended'
  };
};

// MetricCard Component
const MetricCard = ({ label, value, type, note, showDetails = false, table = false }) => {
  const [showNote, setShowNote] = useState(false); // Move useState to the top level

  if (table) {
    return (
      <tr>
        <td className="px-4 py-2 border-b border-gray-700">{label}</td>
        <td className="px-4 py-2 border-b border-gray-700">{value}</td>
        <td className="px-4 py-2 border-b border-gray-700">{note}</td>
      </tr>
    );
  }

  const getBgColor = () => {
    switch (type) {
      case 'success': return 'bg-green-900/20 border-green-500/20';
      case 'warning': return 'bg-yellow-900/20 border-yellow-500/20';
      case 'error': return 'bg-red-900/20 border-red-500/20';
      case 'info':
      default: return 'bg-gray-800/50 border-gray-600/20';
    }
  };

  const getTextColor = () => {
    switch (type) {
      case 'success': return 'text-green-400';
      case 'warning': return 'text-yellow-400';
      case 'error': return 'text-red-400';
      case 'info':
      default: return 'text-gray-200';
    }
  };

  return (
    <div className={`p-4 rounded-lg border ${getBgColor()} relative group`}>
      <div className="flex justify-between items-start">
        <div className="flex-1">
          <div className="flex items-center gap-2">
            <div className="text-sm text-gray-400">{label}</div>
            {note && (
              <button
                onClick={() => setShowNote(!showNote)}
                className="p-1 hover:bg-gray-700/50 rounded-full transition-colors"
              >
                <Info size={14} className="text-gray-400" />
              </button>
            )}
          </div>
          <div className={`text-lg font-semibold ${getTextColor()}`}>{value}</div>
        </div>
      </div>
      {note && showNote && (
        <div className="mt-2 p-2 text-sm bg-gray-900/50 rounded-lg text-gray-300">
          {note}
        </div>
      )}
    </div>
  );
};

// Risk Factor Card Component
const RiskFactorCard = ({ factor, impact, explanation }) => (
  <div className="p-4 bg-gray-800/50 rounded-lg border border-gray-700">
    <div className="flex items-start justify-between gap-4">
      <div>
        <h4 className="font-semibold text-red-400">{factor}</h4>
        <p className="text-sm text-gray-300 mt-1">{explanation}</p>
      </div>
      <div className={`px-2 py-1 rounded-full text-xs font-medium ${
        impact.includes('Critical') ? 'bg-red-900/30 text-red-400' :
        impact.includes('High') ? 'bg-red-900/20 text-red-300' :
        impact.includes('Medium') ? 'bg-yellow-900/20 text-yellow-400' :
        'bg-gray-800/50 text-gray-400'
      }`}>
        {impact}
      </div>
    </div>
  </div>
);

// Safety Check Component
const SafetyCheck = ({ check }) => (
  <div className="flex items-center gap-2 text-green-400">
    <Shield size={16} />
    <span className="text-sm">{check}</span>
  </div>
);

// Update renderMetricsSection function to optionally use table layout
const renderMetricsSection = (title, metrics, description = null, tableLayout = false) => {
  if (tableLayout) {
    return (
      <div className="mt-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-gray-200">{title}</h3>
          {description && <div className="text-sm text-gray-400">{description}</div>}
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-800 rounded-lg">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">Metric</th>
                <th className="px-4 py-2 text-left">Value</th>
                <th className="px-4 py-2 text-left">Note</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(metrics).map(([key, data]) => (
                <MetricCard
                  key={key}
                  label={data.label}
                  value={data.value}
                  type={data.type}
                  note={data.note}
                  table={true}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-6">
      <div className="flex items-center justify-between mb-4">
        <h3 className="text-lg font-semibold text-gray-200">{title}</h3>
        {description && (
          <div className="text-sm text-gray-400">{description}</div>
        )}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {Object.entries(metrics).map(([key, data]) => (
          <MetricCard
            key={key}
            label={data.label}
            value={data.value}
            type={data.type} // Using type instead of risk
            note={data.note}
            showDetails={true}
          />
        ))}
      </div>
    </div>
  );
};

// Generate PDF Function (move outside components)
const generateDetailedPdf = async (results, onProgress) => {
  const doc = new jsPDF();
  const analysis = analyzeMetrics(results);
  let yPos = 20;
  const margin = 20;
  const pageWidth = doc.internal.pageSize.getWidth();
  const contentWidth = pageWidth - (margin * 2);

  const addSection = (title, yOffset = 10) => {
    yPos += yOffset;
    doc.setFillColor(245, 158, 11);
    doc.rect(margin - 2, yPos - 5, contentWidth + 4, 12, 'F');
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(12);
    doc.text(title, margin, yPos + 4);
    yPos += 15;
  };

  const addMetric = (label, value, riskLevel, note) => {
    doc.setFontSize(10);
    doc.setTextColor(80, 80, 80);
    doc.text(label + ':', margin, yPos);

    const riskColors = {
      safe: [39, 174, 96],
      low: [39, 174, 96],
      medium: [241, 196, 15],
      high: [231, 76, 60],
      unknown: [80, 80, 80]
    };

    doc.setTextColor(...(riskColors[riskLevel] || riskColors.unknown));
    doc.text(value.toString(), margin + 80, yPos);

    if (note) {
      yPos += 5;
      doc.setFontSize(8);
      doc.setTextColor(120, 120, 120);
      const noteLines = doc.splitTextToSize(note, contentWidth - 85);
      doc.text(noteLines, margin + 85, yPos);
      yPos += noteLines.length * 4;
    }

    yPos += 8;
  };

  try {
    // Cover Page
    onProgress('Creating report cover...');
    doc.setFillColor(245, 158, 11);
    doc.rect(0, 0, pageWidth, 80, 'F');

    doc.setTextColor(255, 255, 255);
    doc.setFontSize(24);
    doc.text('Honeypot Analysis Report', margin, 40);

    doc.setFontSize(12);
    doc.text(`Generated: ${new Date().toLocaleString()}`, margin, 60);
    doc.text(`Chain: ${results.chain?.name || 'Unknown'}`, margin, 70);

    yPos = 100;

    // Risk Score Section
    onProgress('Adding risk assessment...');
    addSection('Risk Assessment');

    doc.setFillColor(
      analysis.riskLevel === 'High' ? '#fee2e2' :
      analysis.riskLevel === 'Medium' ? '#fef3c7' :
      '#dcfce7'
    );
    doc.rect(margin, yPos, contentWidth, 50, 'F');

    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text(`Risk Score: ${analysis.riskScore}/100`, margin + 10, yPos + 15);
    doc.text(`Risk Level: ${analysis.riskLevel}`, margin + 10, yPos + 30);

    doc.setFontSize(10);
    doc.text(analysis.summary, margin + 10, yPos + 45);
    yPos += 60;

    // Risk Factors
    if (analysis.riskFactors.length > 0) {
      addSection('Risk Factors');
      analysis.riskFactors.forEach(({ factor, impact, explanation }) => {
        doc.setFontSize(10);
        doc.setTextColor(231, 76, 60);
        doc.text(`${factor} (${impact})`, margin + 5, yPos);
        yPos += 5;

        doc.setTextColor(80, 80, 80);
        doc.setFontSize(8);
        const explanationLines = doc.splitTextToSize(explanation, contentWidth - 10);
        doc.text(explanationLines, margin + 10, yPos);
        yPos += explanationLines.length * 4 + 8;
      });
    }

    // Safety Checks
    if (analysis.safetyChecks.length > 0) {
      addSection('Safety Checks');
      analysis.safetyChecks.forEach(check => {
        doc.setFontSize(10);
        doc.setTextColor(39, 174, 96);
        doc.text(`✓ ${check}`, margin + 5, yPos);
        yPos += 8;
      });
    }

    // Detailed Metrics
    Object.entries(analysis.metrics).forEach(([section, metrics]) => {
      addSection(section.replace(/([A-Z])/g, ' $1').trim());
      Object.entries(metrics).forEach(([key, data]) => {
        addMetric(data.label, data.value, data.risk, data.note);
      });
    });

    // Add page break if needed
    if (yPos > doc.internal.pageSize.getHeight() - 40) {
      doc.addPage();
      yPos = 20;
    }

    // Technical Details
    addSection('Technical Details');
    const technicalInfo = [
      ['Contract Address', results.token?.address || 'N/A'],
      ['Analysis Date', new Date().toLocaleString()],
      ['Chain', results.chain?.name || 'Unknown']
    ];

    technicalInfo.forEach(([label, value]) => {
      doc.setFontSize(10);
      doc.setTextColor(80, 80, 80);
      doc.text(`${label}:`, margin, yPos);
      doc.text(value, margin + 80, yPos);
      yPos += 8;
    });

    // Disclaimer
    yPos = doc.internal.pageSize.getHeight() - 30;
    doc.setFontSize(8);
    doc.setTextColor(128, 128, 128);
    const disclaimer = 'This report is generated for informational purposes only. Always conduct your own research (DYOR) before making any investment decisions. Past performance does not guarantee future results.';
    const disclaimerLines = doc.splitTextToSize(disclaimer, contentWidth);
    doc.text(disclaimerLines, margin, yPos);

    onProgress('Saving report...');
    const fileName = `honeypot-analysis-${results.token?.address || 'report'}-${Date.now()}.pdf`;
    doc.save(fileName);
    onProgress('Report generated successfully!');
  } catch (error) {
    console.error('PDF generation error:', error);
    throw error;
  }
};

// Export Modal Component with enhanced logging
const ExportModal = ({ isOpen, onClose, results }) => {
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const [progress, setProgress] = useState('');
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const downloadJson = () => {
    console.log('Preparing JSON export');
    const data = {
      analysis: analyzeMetrics(results),
      rawData: results,
      timestamp: new Date().toISOString(),
      metadata: {
        chain: results.chain?.name || 'Unknown',
        version: '1.0',
        reportType: 'honeypot-analysis'
      }
    };

    const dataStr = JSON.stringify(data, null, 2);
    const dataBlob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.download = `honeypot-analysis-${results.token?.address || 'report'}-${Date.now()}.json`;
    link.href = url;
    link.click();
    URL.revokeObjectURL(url);
    console.log('JSON export completed');
  };

  const handlePdfExport = async () => {
    try {
      console.log('Starting PDF generation');
      setGeneratingPdf(true);
      setError('');
      await generateDetailedPdf(results, (message) => {
        console.log('PDF generation progress:', message);
        setProgress(message);
      });
      console.log('PDF generation completed');
    } catch (err) {
      console.error('PDF generation failed:', err);
      setError(err.message || 'Failed to generate PDF report');
    } finally {
      setGeneratingPdf(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-gray-800 rounded-xl p-6 max-w-md w-full mx-4 border-2 border-amber-400/20"
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-amber-400">Export Analysis Report</h3>
          <button
            onClick={onClose}
            className="p-1 hover:bg-gray-700 rounded-lg transition-colors"
            disabled={generatingPdf}
          >
            <X size={20} className="text-gray-400" />
          </button>
        </div>

        <div className="space-y-4">
          <button
            onClick={downloadJson}
            disabled={generatingPdf}
            className="w-full p-4 bg-gray-700/50 rounded-xl border border-amber-400/20
              hover:bg-gray-700 transition-colors flex items-center gap-3
              disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <FileJson size={24} className="text-amber-400" />
            <div className="text-left">
              <div className="font-semibold">Download Complete Data</div>
              <div className="text-sm text-gray-400">Full analysis and raw data in JSON format</div>
            </div>
          </button>

          <button
            onClick={handlePdfExport}
            disabled={generatingPdf}
            className="w-full p-4 bg-gray-700/50 rounded-xl border border-amber-400/20
              hover:bg-gray-700 transition-colors flex items-center gap-3
              disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <FileText size={24} className="text-amber-400" />
            <div className="text-left flex-1">
              <div className="font-semibold">Download PDF Report</div>
              <div className="text-sm text-gray-400">Comprehensive analysis with metrics and explanations</div>
            </div>
            {generatingPdf && <Loader2 className="animate-spin text-amber-400" size={20} />}
          </button>
        </div>

        {generatingPdf && progress && (
          <div className="mt-4 p-4 bg-gray-700/50 rounded-lg">
            <div className="flex items-center gap-2 text-sm text-gray-300">
              <Loader2 className="animate-spin text-amber-400" size={16} />
              {progress}
            </div>
          </div>
        )}

        {error && (
          <div className="mt-4 p-4 bg-red-900/50 rounded-lg border border-red-500/50">
            <div className="flex items-center gap-2 text-sm text-red-200">
              <AlertTriangle size={16} />
              {error}
            </div>
          </div>
        )}

        {!generatingPdf && !error && (
          <div className="mt-6 text-center text-sm text-gray-400">
            Reports include comprehensive analysis, risk assessment, and raw data
          </div>
        )}
      </motion.div>
    </div>
  );
};

// Main HoneypotDetector Component
const HoneypotDetector = () => {
  const [isDark, setIsDark] = useState(true);
  const [address, setAddress] = useState('');
  const [chainId, setChainId] = useState(CHAIN_IDS.BASE);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [error, setError] = useState('');
  const [results, setResults] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    console.log('Address input changed:', newAddress);
    setAddress(newAddress);
  };

  const handleChainChange = (e) => {
    const newChainId = Number(e.target.value);
    console.log('Chain selection changed:', newChainId);
    setChainId(newChainId);
  };

  // Enhanced API call with better error handling and logging
  const handleHoneypotCheck = async (e) => {
    e.preventDefault();
    console.log('Starting honeypot analysis for:', address);
    console.log('Selected chain:', chainId);

    if (!address) {
      console.log('No address provided');
      setError('Please enter a token address');
      return;
    }

    if (!/^0x[a-fA-F0-9]{40}$/.test(address)) {
      console.log('Invalid address format:', address);
      setError('Please enter a valid token address');
      return;
    }

    setError('');
    setIsAnalyzing(true);
    setResults(null);

    try {
      const params = new URLSearchParams({
        address,
        chainID: chainId
      });

      const fullUrl = `${HONEYPOT_API_URL}/IsHoneypot?${params}`;
      console.log('API request URL:', fullUrl);

      const response = await fetch(fullUrl);
      console.log('API response status:', response.status);

      if (!response.ok) {
        throw new Error(`API error: ${response.status}`);
      }

      const data = await response.json();
      console.log('Raw API response:', data);

      // Validate response data
      if (!data) {
        throw new Error('Invalid response from API');
      }

      // Process and store results
      setResults(data);
      console.log('Analysis completed successfully');
    } catch (err) {
      console.error('Analysis failed:', err);
      setError(err.message || 'Failed to check honeypot status');
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className={`min-h-screen font-['Rajdhani'] ${
      isDark
        ? 'bg-gradient-to-b from-black via-gray-900 to-black text-white'
        : 'bg-gradient-to-b from-amber-50 via-white to-amber-50 text-gray-900'
    } transition-all duration-500 relative overflow-hidden`}>

      {/* Navigation */}
      <nav className="fixed w-full z-50 backdrop-blur-md bg-opacity-70">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <Link to="/">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="text-2xl font-bold flex items-center gap-2"
            >
              <ArrowLeft size={24} className="text-amber-400" />
              <span className={`${isDark ? 'text-amber-400' : 'text-amber-600'}`}>
                🍯 $HONEY
              </span>
            </motion.div>
          </Link>
          <button
            onClick={() => setIsDark(!isDark)}
            className="p-2 rounded-full hover:bg-amber-400/20 transition-colors"
          >
            {isDark ?
              <Sun size={24} className="text-amber-400" /> :
              <Moon size={24} className="text-amber-600" />
            }
          </button>
        </div>
      </nav>

      {/* Main Content */}
      <div className="container mx-auto pt-24 px-6 pb-12">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-8">
            <h1 className="text-4xl md:text-5xl font-bold mb-4 text-amber-400">
              Honeypot Detector
            </h1>
            <p className="text-gray-300 text-lg">
              Advanced token contract analysis and risk assessment
            </p>
          </div>

          {/* Search Form */}
          <form onSubmit={handleHoneypotCheck} className="space-y-4">
            <div className="flex gap-2">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="Enter token address (0x...)"
                  value={address}
                  onChange={handleAddressChange}
                  className="w-full px-4 py-3 bg-gray-800/50 border-2 border-amber-400/20 
                    rounded-xl focus:outline-none focus:border-amber-400/40 
                    text-white placeholder-gray-400"
                  disabled={isAnalyzing}
                />
                <select
                  value={chainId}
                  onChange={handleChainChange}
                  className="absolute right-14 top-1/2 -translate-y-1/2 bg-gray-700/50 
                    border border-amber-400/20 rounded-lg px-2 py-1 text-sm"
                  disabled={isAnalyzing}
                >
                  <option value={CHAIN_IDS.BASE}>Base</option>
                  <option value={CHAIN_IDS.ETHEREUM}>Ethereum</option>
                </select>
                <button
                  type="submit"
                  disabled={isAnalyzing}
                  className="absolute right-2 top-1/2 -translate-y-1/2 p-2 
                    text-amber-400 hover:text-amber-300 disabled:text-gray-500
                    transition-colors"
                >
                  {isAnalyzing ? (
                    <Loader2 className="animate-spin" size={24} />
                  ) : (
                    <Search size={24} />
                  )}
                </button>
              </div>
            </div>
          </form>

          {error && (
            <div className="mt-4 p-4 bg-red-900/50 border border-red-500 rounded-lg text-red-200">
              <div className="flex items-center gap-2">
                <AlertTriangle size={16} />
                <span className="font-semibold">Error</span>
              </div>
              <p className="mt-1">{error}</p>
            </div>
          )}

          {/* Results Section */}
          {results && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="mt-8"
            >
              {/* Analysis Results */}
              {(() => {
                const analysis = analyzeMetrics(results);
                return (
                  <div className="space-y-6">
                    {/* Risk Score Banner */}
                    <div className={`p-6 rounded-xl ${
                      analysis.riskLevel === 'High' ? 'bg-red-900/20 border-red-500/20' :
                      analysis.riskLevel === 'Medium' ? 'bg-yellow-900/20 border-yellow-500/20' :
                      'bg-green-900/20 border-green-500/20'
                    } border-2`}>
                      <div className="flex justify-between items-center mb-4">
                        <div>
                          <h2 className="text-2xl font-bold">Risk Assessment</h2>
                          <p className="text-gray-400">Analysis completed {new Date().toLocaleString()}</p>
                        </div>
                        <button
                          onClick={() => setShowExportModal(true)}
                          className="flex items-center gap-2 px-3 py-1.5 bg-amber-400/20 text-amber-400
                            rounded-lg hover:bg-amber-400/30 transition-colors"
                        >
                          <Download size={16} />
                          Export Report
                        </button>
                      </div>

                      <div className="flex flex-col md:flex-row md:items-center gap-4 mb-4">
                        <div className="text-4xl font-bold">
                          {analysis.riskScore}/100
                        </div>
                        <div className={`text-lg font-semibold ${
                          analysis.riskLevel === 'High' ? 'text-red-400' :
                          analysis.riskLevel === 'Medium' ? 'text-yellow-400' :
                          'text-green-400'
                        }`}>
                          {analysis.riskLevel} Risk Level
                        </div>
                      </div>

                      <p className="text-lg text-gray-300">{analysis.summary}</p>

                      {/* Risk Factors */}
                      {analysis.riskFactors.length > 0 && (
                        <div className="mt-6 space-y-3">
                          <h3 className="font-semibold text-red-400">Risk Factors:</h3>
                          {analysis.riskFactors.map((factor, index) => (
                            <RiskFactorCard key={index} {...factor} />
                          ))}
                        </div>
                      )}

                      {/* Safety Checks */}
                      {analysis.safetyChecks.length > 0 && (
                        <div className="mt-6 space-y-2">
                          <h3 className="font-semibold text-green-400">Safety Checks Passed:</h3>
                          {analysis.safetyChecks.map((check, index) => (
                            <SafetyCheck key={index} check={check} />
                          ))}
                        </div>
                      )}
                    </div>

                    {/* Detailed Metrics */}
                    {renderMetricsSection('Contract Information', analysis.metrics.contractInfo)}
                    {renderMetricsSection(
                      'Transaction Analysis',
                      analysis.metrics.transactionCosts,
                      'Analysis of gas costs and transaction taxes'
                    )}
                    {renderMetricsSection(
                      'Trading Limits',
                      analysis.metrics.tradingLimits,
                      'Restrictions on transactions and wallet sizes'
                    )}
                    {renderMetricsSection(
                      'Contract Safety',
                      analysis.metrics.contractSafety,
                      'Contract verification and security features'
                    )}
                    {renderMetricsSection(
                      'Holder Analysis',
                      analysis.metrics.holderAnalysis,
                      'Insights into token holders and transfer statistics',
                      true // Enable table layout
                    )}
                    {renderMetricsSection(
                      'Liquidity Information',
                      analysis.metrics.liquidityInfo,
                      'Details about the liquidity pool',
                      true // Enable table layout
                    )}
                    {renderMetricsSection(
                      'Contract Code Analysis',
                      analysis.metrics.contractCodeAnalysis,
                      'Analysis of the contract code features',
                      true // Enable table layout
                    )}

                    {/* External Links */}
                    <div className="flex flex-wrap gap-4 justify-center mt-8">
                      <a
                        href={`https://${chainId === CHAIN_IDS.BASE ? 'basescan' : 'etherscan'}.io/token/${address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 px-4 py-2 bg-amber-400 
                          text-gray-900 rounded-lg hover:bg-amber-500 transition-colors"
                      >
                        View on {chainId === CHAIN_IDS.BASE ? 'Basescan' : 'Etherscan'}
                        <ExternalLink size={16} />
                      </a>
                      <a
                        href={`https://dexscreener.com/${chainId === CHAIN_IDS.BASE ? 'base' : 'ethereum'}/${address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-2 px-4 py-2 border-2 
                          border-amber-400 text-amber-400 rounded-lg hover:bg-amber-400/20 
                          transition-colors"
                      >
                        View on DexScreener
                        <ExternalLink size={16} />
                      </a>
                    </div>
                  </div>
                );
              })()}
            </motion.div>
          )}

          {/* Export Modal */}
          <AnimatePresence>
            {showExportModal && (
              <ExportModal
                isOpen={showExportModal}
                onClose={() => setShowExportModal(false)}
                results={results}
              />
            )}
          </AnimatePresence>

          {/* Quick Tips */}
          {!results && !isAnalyzing && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="mt-12 p-6 bg-gray-800/50 rounded-xl border-2 border-amber-400/20"
            >
              <h2 className="text-xl font-bold mb-4 text-amber-400">
                Understanding Token Safety
              </h2>
              <div className="grid gap-6 md:grid-cols-2">
                <div>
                  <h3 className="font-semibold mb-2 text-amber-400">What We Check:</h3>
                  <ul className="space-y-2 text-gray-300">
                    <li className="flex items-start gap-2">
                      <span className="text-amber-400">•</span>
                      Contract verification and source code
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-amber-400">•</span>
                      Buy and sell transaction costs
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-amber-400">•</span>
                      Tax rates and trading restrictions
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-amber-400">•</span>
                      Contract security features
                    </li>
                  </ul>
                </div>
                <div>
                  <h3 className="font-semibold mb-2 text-amber-400">Risk Indicators:</h3>
                  <ul className="space-y-2 text-gray-300">
                    <li className="flex items-start gap-2">
                      <span className="text-amber-400">•</span>
                      High transaction costs
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-amber-400">•</span>
                      Excessive tax rates
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-amber-400">•</span>
                      Suspicious contract features
                    </li>
                    <li className="flex items-start gap-2">
                      <span className="text-amber-400">•</span>
                      Trading restrictions
                    </li>
                  </ul>
                </div>
              </div>

              <div className="mt-6 p-4 bg-amber-400/10 rounded-lg border border-amber-400/20">
                <p className="text-sm text-gray-300">
                  <strong className="text-amber-400">Pro Tip:</strong> Always use multiple analysis tools and verify contract functionality with small test transactions before making significant investments.
                </p>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HoneypotDetector;

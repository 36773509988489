import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

// Game Configuration Constants
const GRID_SIZE = 3;
const SPAWN_INTERVAL = 1500;
const HONEY_RESET_TIME = 3000;
const EVIL_RESET_TIME = 2000;
const POWERUP_DURATION = 5000;
const POWERUP_SPAWN_CHANCE = 0.1;
const CURSOR_FEEDBACK_DURATION = 500;
const HAMMER_SPAWN_INTERVAL = 2000;
const MAX_HAMMERS = 10;
const COMBO_WINDOW = 2000; // 2 seconds to maintain combo
const MAX_COMBO = 5;
const SPECIAL_CELL_CHANCE = 0.05; // Reduced chance for special cells

// Game Modes Configuration
const MODES = {
  CLASSIC: {
    name: 'Classic',
    duration: 60,
    evilChance: 0.3,
    description: 'Classic 60-second game with balanced gameplay'
  },
  SPEED: {
    name: 'Speed Run',
    duration: 30,
    evilChance: 0.2,
    description: 'Fast-paced 30-second challenge with fewer evil pots'
  },
  ZEN: {
    name: 'Zen Mode',
    duration: 60,
    evilChance: 0,
    description: 'Relaxed mode with no evil honeypots'
  },
};

// Power-up Types and Configuration
const POWERUPS = {
  SHIELD: {
    emoji: '🛡️',
    description: 'Protects from next evil honeypot',
    duration: null
  },
  MULTIPLIER: {
    emoji: '✨',
    description: '2x points for 5 seconds',
    duration: POWERUP_DURATION
  },
};

// Special Cells Configuration
const SPECIAL_CELLS = {
  GOLDEN: { emoji: '👑', points: 50, chance: SPECIAL_CELL_CHANCE },
  BOMB: { emoji: '💣', effect: 'disableNeighbors', chance: SPECIAL_CELL_CHANCE },
  PORTAL: { emoji: '🌀', effect: 'teleport', chance: SPECIAL_CELL_CHANCE },
};

// Achievement Configuration
const ACHIEVEMENTS = {
  FIRST_BLOOD: { name: "First Honey", description: "Find your first honeypot", points: 100 },
  COMBO_MASTER: { name: "Combo Master", description: "Get a 5x combo", points: 500 },
  HAMMER_HUNTER: { name: "Hammer Hunter", description: "Click 50 hammers", points: 1000 },
  SHIELD_MASTER: { name: "Shield Master", description: "Block 10 evil honeypots", points: 800 },
};

// Particle Effect Types
const PARTICLE_TYPES = {
  honeypot: { emoji: '✨', color: 'text-amber-400', count: 6 },
  evil: { emoji: '💥', color: 'text-red-500', count: 8 },
  powerup: { emoji: '🌟', color: 'text-purple-400', count: 6 },
  miss: { emoji: '❌', color: 'text-gray-400', count: 1 },
  explode: { emoji: '💥', color: 'text-yellow-400', count: 12 },
  combo: { emoji: '', color: 'text-green-400', count: 1 }, // Combo text will be added dynamically
  golden: { emoji: '✨', color: 'text-yellow-500', count: 10 },
};

// Floating Hammers Component
const FloatingHammers = ({ onHammerClick }) => {
  const [hammers, setHammers] = useState([]);

  useEffect(() => {
    const createHammer = () => {
      const id = Date.now() + Math.random();
      const startX = Math.random() * (window.innerWidth - 40);
      return {
        id,
        x: startX,
        y: window.innerHeight + 30,
        rotation: Math.random() * 360,
      };
    };

    const addHammer = () => {
      setHammers(current => {
        const newHammers = [...current, createHammer()];
        if (newHammers.length > MAX_HAMMERS) {
          return newHammers.slice(-MAX_HAMMERS);
        }
        return newHammers;
      });
    };

    const interval = setInterval(addHammer, HAMMER_SPAWN_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  const handleHammerClick = (hammer, event) => {
    setHammers(current => current.filter(h => h.id !== hammer.id));
    onHammerClick(event.clientX, event.clientY);
  };

  return (
    <div className="fixed inset-0 pointer-events-none">
      <AnimatePresence>
        {hammers.map(hammer => (
          <motion.div
            key={hammer.id}
            initial={{ x: hammer.x, y: hammer.y, rotate: hammer.rotation, opacity: 0.7 }}
            animate={{ 
              y: -100,
              rotate: hammer.rotation + 360,
              x: hammer.x + Math.sin(hammer.rotation) * 50
            }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ duration: 8, ease: "linear" }}
            className="absolute text-3xl cursor-pointer pointer-events-auto"
            onClick={(e) => handleHammerClick(hammer, e)}
            whileHover={{ scale: 1.2 }}
          >
            🔨
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

// Enhanced ParticleEffect Component
const ParticleEffect = ({ x, y, type, text }) => {
  const config = PARTICLE_TYPES[type];
  const [particles] = useState(() => {
    return Array.from({ length: config.count }, (_, i) => ({
      id: i,
      angle: (360 / config.count) * i,
      distance: Math.random() * (type === 'explode' ? 100 : 40) + 20,
    }));
  });

  return (
    <>
      {particles.map((particle) => (
        <motion.div
          key={particle.id}
          className={`absolute pointer-events-none ${config.color} text-lg`}
          style={{ 
            left: x, 
            top: y,
            position: 'fixed',
            zIndex: 1000,
          }}
          initial={{ 
            scale: 1,
            x: 0,
            y: 0,
            opacity: 1,
          }}
          animate={{ 
            scale: type === 'miss' ? 1.5 : 0,
            x: Math.cos(particle.angle * Math.PI / 180) * particle.distance,
            y: Math.sin(particle.angle * Math.PI / 180) * particle.distance,
            opacity: 0,
          }}
          transition={{ 
            duration: type === 'explode' ? 0.7 : 0.5,
            ease: "easeOut",
          }}
        >
          {text || config.emoji}
        </motion.div>
      ))}
    </>
  );
};

// Cursor Effect Component
const CursorEffect = ({ emoji, x, y }) => (
  <motion.div
    className="fixed pointer-events-none text-2xl"
    style={{ left: x, top: y, zIndex: 1000 }}
    initial={{ scale: 1, opacity: 1 }}
    animate={{ scale: 1.5, opacity: 0 }}
    exit={{ scale: 0 }}
    transition={{ duration: 0.5 }}
  >
    {emoji}
  </motion.div>
);

// Game Cell Component
const Cell = ({ index, onDig, isDisabled, discovered, powerup }) => {
  return (
    <motion.button
      data-index={index}
      className={`w-20 h-20 rounded-xl flex items-center justify-center text-3xl 
        ${isDisabled ? 'bg-red-900/50' : 'bg-gray-700/50'} 
        ${!discovered && !isDisabled ? 'hover:bg-gray-600/50' : ''}
        transition-colors duration-200 border border-amber-400/20
        relative`}
      disabled={isDisabled || discovered}
      onClick={(e) => onDig(index, e)}
      whileHover={!discovered && !isDisabled ? { scale: 1.05 } : {}}
      whileTap={!discovered && !isDisabled ? { scale: 0.95 } : {}}
    >
      <AnimatePresence>
        {powerup && (
          <motion.div
            className="absolute top-0 right-0 text-sm"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            {powerup.emoji}
          </motion.div>
        )}
        {!discovered ? (
          <motion.span
            initial={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            🔨
          </motion.span>
        ) : (
          <motion.span
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
          >
            {discovered}
          </motion.span>
        )}
      </AnimatePresence>
    </motion.button>
  );
};

// Achievement Notification Component
const AchievementNotification = ({ achievement }) => (
  <motion.div
    initial={{ x: 300, opacity: 0 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: 300, opacity: 0 }}
    className="fixed right-4 top-20 bg-amber-400 text-gray-900 p-4 rounded-lg shadow-lg"
    style={{ zIndex: 1000 }}
  >
    <h3 className="font-bold text-lg">🏆 Achievement Unlocked!</h3>
    <p>{achievement.name}</p>
    <p className="text-sm">+{achievement.points} points</p>
  </motion.div>
);

// Instructions Modal Component
const InstructionsModal = ({ onClose }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
    onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}
    style={{ zIndex: 1000 }}
  >
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      className="bg-gray-800 p-8 rounded-xl max-w-md mx-4"
      onClick={e => e.stopPropagation()}
    >
      <h2 className="text-2xl font-bold text-amber-400 mb-4">How to Play</h2>
      
      <div className="space-y-4 text-gray-200">
        <p>🎯 <strong>Goal:</strong> Dig for honeypots and score points!</p>
        
        <div className="space-y-2">
          <p><strong>Items:</strong></p>
          <p>🍯 Honeypot: +10 points (resets in 3s)</p>
          <p>😈 Evil Honeypot: -10 points (resets in 2s)</p>
          <p>❌ Miss: Nothing found, try again!</p>
          <p>👑 Golden Pot: +50 points!</p>
        </div>

        <div className="space-y-2">
          <p><strong>Power-ups:</strong></p>
          <p>🛡️ Shield: Protects from one evil honeypot</p>
          <p>✨ Multiplier: Double points for 5 seconds</p>
        </div>

        <div className="space-y-2">
          <p><strong>Combos:</strong></p>
          <p>🔥 Quick successive finds increase your combo multiplier!</p>
          <p>⚡ Up to 5x points possible!</p>
        </div>

        <div className="space-y-2">
          <p><strong>Special:</strong></p>
          <p>🔨 Click floating hammers for explosions!</p>
          <p>💣 Watch out for bombs!</p>
          <p>🌀 Portals might help or hurt!</p>
        </div>
      </div>

      <button
        onClick={onClose}
        className="mt-6 w-full py-2 bg-amber-400 text-gray-900 rounded-lg font-bold
          hover:bg-amber-500 transition-colors"
      >
        Got it!
      </button>
    </motion.div>
  </motion.div>
);

// Achievements Modal Component
const AchievementsModal = ({ achievements, onClose }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
    onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}
    style={{ zIndex: 1000 }}
  >
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      className="bg-gray-800 p-8 rounded-xl max-w-lg mx-4"
      onClick={e => e.stopPropagation()}
    >
      <h2 className="text-2xl font-bold text-amber-400 mb-4 text-center">Achievements</h2>
      <div className="grid gap-2">
        {Object.entries(ACHIEVEMENTS).map(([id, achievement]) => {
          const isUnlocked = achievements[id];
          return (
            <div 
              key={id} 
              className={`p-2 rounded-lg ${
                isUnlocked 
                  ? 'bg-amber-400/20 text-amber-400' 
                  : 'bg-gray-700/50 text-gray-400'
              }`}
            >
              <div className="flex justify-between items-center">
                <span>{achievement.name}</span>
                <span>{isUnlocked ? '🏆' : '🔒'}</span>
              </div>
              <div className="text-sm opacity-75">
                {achievement.description}
              </div>
            </div>
          );
        })}
      </div>
      <button
        onClick={onClose}
        className="mt-6 w-full py-2 bg-amber-400 text-gray-900 rounded-lg font-bold
          hover:bg-amber-500 transition-colors"
      >
        Close
      </button>
    </motion.div>
  </motion.div>
);

// High Scores Modal Component
const HighScoresModal = ({ highScores, onClose }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center"
    onClick={(e) => {
      if (e.target === e.currentTarget) onClose();
    }}
    style={{ zIndex: 1000 }}
  >
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      className="bg-gray-800 p-8 rounded-xl max-w-lg mx-4"
      onClick={e => e.stopPropagation()}
    >
      <h2 className="text-2xl font-bold text-amber-400 mb-4 text-center">High Scores</h2>
      {Object.entries(MODES).map(([modeKey, modeData]) => (
        <div key={modeKey} className="mb-4">
          <h3 className="text-xl text-amber-400 mb-2">{modeData.name}</h3>
          <ul className="list-decimal list-inside text-gray-300">
            {highScores[modeKey]?.length > 0 ? (
              highScores[modeKey].map((score, index) => (
                <li key={index}>Score: {score}</li>
              ))
            ) : (
              <li>No scores yet.</li>
            )}
          </ul>
        </div>
      ))}
      <button
        onClick={onClose}
        className="mt-4 w-full py-2 bg-amber-400 text-gray-900 rounded-lg font-bold
          hover:bg-amber-500 transition-colors"
      >
        Close
      </button>
    </motion.div>
  </motion.div>
);

// Main Game Component
const Game = () => {
  // State Management
  const [mode, setMode] = useState(null);
  const [showInstructions, setShowInstructions] = useState(false);
  const [showAchievementsModal, setShowAchievementsModal] = useState(false);
  const [showHighScoresModal, setShowHighScoresModal] = useState(false);
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [gameActive, setGameActive] = useState(false);
  const [cells, setCells] = useState(Array(GRID_SIZE * GRID_SIZE).fill(null));
  const [disabledCells, setDisabledCells] = useState(Array(GRID_SIZE * GRID_SIZE).fill(false));
  const [discovered, setDiscovered] = useState(Array(GRID_SIZE * GRID_SIZE).fill(null));
  const [particles, setParticles] = useState([]);
  const [cursorEffects, setCursorEffects] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [combo, setCombo] = useState(1);
  const [lastSuccessTime, setLastSuccessTime] = useState(0);
  const [achievements, setAchievements] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('honeypot-achievements')) || {};
    } catch {
      return {};
    }
  });
  const [showAchievement, setShowAchievement] = useState(null);
  const [hammerCount, setHammerCount] = useState(0);

  // Initialize high scores from localStorage or defaults
  const [highScores, setHighScores] = useState(() => {
    try {
      const saved = localStorage.getItem('honeypot-highscores');
      if (saved) {
        const parsed = JSON.parse(saved);
        // Ensure each mode's high scores are arrays
        const validatedHighScores = Object.keys(MODES).reduce((acc, mode) => {
          const scores = parsed[mode];
          if (Array.isArray(scores)) {
            acc[mode] = scores;
          } else if (typeof scores === 'number') {
            acc[mode] = [scores];
          } else {
            acc[mode] = [];
          }
          return acc;
        }, {});
        return validatedHighScores;
      } else {
        return Object.keys(MODES).reduce((acc, mode) => ({
          ...acc,
          [mode]: []
        }), {});
      }
    } catch (error) {
      console.error('Error loading high scores:', error);
      return Object.keys(MODES).reduce((acc, mode) => ({ ...acc, [mode]: [] }), {});
    }
  });

  // Power-ups state management
  const [powerups, setPowerups] = useState({
    active: null,
    cells: Array(GRID_SIZE * GRID_SIZE).fill(null),
  });

  // Save high scores and achievements
  useEffect(() => {
    try {
      localStorage.setItem('honeypot-highscores', JSON.stringify(highScores));
      localStorage.setItem('honeypot-achievements', JSON.stringify(achievements));
    } catch (error) {
      console.error('Error saving game data:', error);
      setErrorMessage('Failed to save game data');
    }
  }, [highScores, achievements]);

  const unlockAchievement = useCallback((achievementId) => {
    if (!achievements[achievementId]) {
      const achievement = ACHIEVEMENTS[achievementId];
      setAchievements(prev => ({ ...prev, [achievementId]: Date.now() }));
      setShowAchievement(achievement);
      setScore(s => s + achievement.points);
      setTimeout(() => setShowAchievement(null), 3000);
    }
  }, [achievements]);

  // Game initialization
  const startGame = (selectedMode) => {
    if (!MODES[selectedMode]) {
      console.error('Invalid game mode selected:', selectedMode);
      return;
    }

    setMode(selectedMode);
    setScore(0);
    setTimeLeft(MODES[selectedMode].duration);
    setGameActive(true);
    setCells(Array(GRID_SIZE * GRID_SIZE).fill(null));
    setDisabledCells(Array(GRID_SIZE * GRID_SIZE).fill(false));
    setDiscovered(Array(GRID_SIZE * GRID_SIZE).fill(null));
    setPowerups({
      active: null,
      cells: Array(GRID_SIZE * GRID_SIZE).fill(null),
    });
    setCombo(1);
    setLastSuccessTime(0);
    setErrorMessage(null);
    setParticles([]);
    setCursorEffects([]);
  };

  // Particle effect management
  const addParticle = useCallback((index, type, { x, y, text }) => {
    try {
      const particle = {
        id: Date.now() + Math.random(),
        x,
        y,
        type,
        text
      };

      setParticles(current => [...current, particle]);
      setTimeout(() => {
        setParticles(current => current.filter(p => p.id !== particle.id));
      }, 700);
    } catch (error) {
      console.error('Error adding particle effect:', error);
    }
  }, []);

  // Handle hammer explosion
  const handleHammerClick = useCallback((x, y) => {
    addParticle(null, 'explode', { x, y });
    setHammerCount(count => {
      const newCount = count + 1;
      if (newCount >= 50) {
        unlockAchievement('HAMMER_HUNTER');
      }
      return newCount;
    });
  }, [addParticle, unlockAchievement]);

  // Power-up spawn logic
  const spawnPowerup = useCallback(() => {
    if (!gameActive || Math.random() > POWERUP_SPAWN_CHANCE) return;

    setPowerups(current => {
      try {
        const available = cells
          .map((cell, index) => (!cell && !current.cells[index] ? index : -1))
          .filter(index => index !== -1);

        if (available.length === 0) return current;

        const randomIndex = available[Math.floor(Math.random() * available.length)];
        const powerupType = Object.keys(POWERUPS)[
          Math.floor(Math.random() * Object.keys(POWERUPS).length)
        ];

        const newCells = [...current.cells];
        newCells[randomIndex] = POWERUPS[powerupType];

        return {
          ...current,
          cells: newCells,
        };
      } catch (error) {
        console.error('Error spawning power-up:', error);
        return current;
      }
    });
  }, [gameActive, cells]);

  // Honeypot spawn logic with adjusted probabilities
  const spawnHoneypot = useCallback(() => {
    if (!gameActive) return;

    setCells(current => {
      try {
        const available = current
          .map((cell, index) => (!cell && !disabledCells[index] ? index : -1))
          .filter(index => index !== -1);

        if (available.length === 0) return current;

        const randomIndex = available[Math.floor(Math.random() * available.length)];
        const newCells = [...current];

        // Decide what to spawn based on probabilities
        const rand = Math.random();

        if (rand < SPECIAL_CELL_CHANCE) {
          // Spawn special cell
          const specialCellTypes = Object.entries(SPECIAL_CELLS);
          const randomSpecialCell = specialCellTypes[Math.floor(Math.random() * specialCellTypes.length)];
          newCells[randomIndex] = randomSpecialCell[1].emoji;
        } else if (rand < MODES[mode].evilChance + SPECIAL_CELL_CHANCE) {
          // Spawn evil pot
          newCells[randomIndex] = '😈';
        } else {
          // Spawn honeypot
          newCells[randomIndex] = '🍯';
        }

        return newCells;
      } catch (error) {
        console.error('Error spawning honeypot:', error);
        return current;
      }
    });

    spawnPowerup();
  }, [gameActive, disabledCells, mode, spawnPowerup]);

  // Cell reset logic
  const resetCell = useCallback((index, delay) => {
    setTimeout(() => {
      try {
        setDisabledCells(prev => {
          const updated = [...prev];
          updated[index] = false;
          return updated;
        });
        setDiscovered(prev => {
          const updated = [...prev];
          updated[index] = null;
          return updated;
        });
        setCells(prev => {
          const updated = [...prev];
          updated[index] = null;
          return updated;
        });
      } catch (error) {
        console.error('Error resetting cell:', error);
      }
    }, delay);
  }, []);

  // Handle dig action with all effects
  const handleDig = useCallback((index, event) => {
    if (!gameActive || disabledCells[index]) return;

    const cell = cells[index];
    const powerup = powerups.cells[index];
    const clickX = event.clientX;
    const clickY = event.clientY;

    // Handle empty cell click
    if (!cell && !powerup) {
      addParticle(index, 'miss', { x: clickX, y: clickY });
      setCombo(1); // Reset combo on miss
      return;
    }

    // Helper function to add cursor effect
    const addCursorEffect = (emoji) => {
      const effectId = Date.now();
      setCursorEffects(current => [...current, {
        id: effectId,
        emoji,
        x: clickX,
        y: clickY
      }]);
      setTimeout(() => {
        setCursorEffects(current => current.filter(effect => effect.id !== effectId));
      }, CURSOR_FEEDBACK_DURATION);
    };

    // Handle power-up collection
    if (powerup) {
      addCursorEffect(powerup.emoji);
      addParticle(index, 'powerup', { x: clickX, y: clickY });
      setPowerups(current => {
        const newCells = [...current.cells];
        newCells[index] = null;
        return {
          cells: newCells,
          active: powerup,
        };
      });
      return;
    }

    // Handle special cells
    if (cell === SPECIAL_CELLS.GOLDEN.emoji) {
      addCursorEffect('👑');
      addParticle(index, 'golden', { x: clickX, y: clickY });
      setScore(s => s + SPECIAL_CELLS.GOLDEN.points);
      setDisabledCells(current => {
        const newDisabled = [...current];
        newDisabled[index] = true;
        return newDisabled;
      });
      setDiscovered(current => {
        const newDiscovered = [...current];
        newDiscovered[index] = cell;
        return newDiscovered;
      });
      resetCell(index, HONEY_RESET_TIME);
      return;
    }

    // Handle bomb
    if (cell === SPECIAL_CELLS.BOMB.emoji) {
      addCursorEffect('💣');
      addParticle(index, 'explode', { x: clickX, y: clickY });
      // Disable neighboring cells
      const neighbors = getNeighborIndices(index);
      setDisabledCells(current => {
        const newDisabled = [...current];
        neighbors.forEach(i => newDisabled[i] = true);
        return newDisabled;
      });
      setTimeout(() => {
        setDisabledCells(prev => {
          const updated = [...prev];
          neighbors.forEach(i => updated[i] = false);
          return updated;
        });
      }, EVIL_RESET_TIME);
      // Reset the bomb cell
      setCells(prev => {
        const updated = [...prev];
        updated[index] = null;
        return updated;
      });
      setDiscovered(prev => {
        const updated = [...prev];
        updated[index] = null;
        return updated;
      });
      return;
    }

    // Handle portal
    if (cell === SPECIAL_CELLS.PORTAL.emoji) {
      addCursorEffect('🌀');
      addParticle(index, 'powerup', { x: clickX, y: clickY });
      // Teleport to random empty cell
      const available = cells
        .map((c, i) => (!c && !disabledCells[i] && i !== index ? i : -1))
        .filter(i => i !== -1);
      if (available.length > 0) {
        const targetIndex = available[Math.floor(Math.random() * available.length)];
        handleDig(targetIndex, event);
      }
      // Reset the portal cell
      setCells(prev => {
        const updated = [...prev];
        updated[index] = null;
        return updated;
      });
      setDiscovered(prev => {
        const updated = [...prev];
        updated[index] = null;
        return updated;
      });
      return;
    }

    // Handle regular honeypot
    if (cell === '🍯') {
      // Check combo timing
      const now = Date.now();
      if (now - lastSuccessTime < COMBO_WINDOW) {
        setCombo(c => Math.min(c + 1, MAX_COMBO));
      } else {
        setCombo(1);
      }
      setLastSuccessTime(now);

      // Calculate points with combo
      const basePoints = powerups.active?.emoji === '✨' ? 20 : 10;
      const comboPoints = basePoints * combo;
      setScore(s => s + comboPoints);

      // Effects
      addCursorEffect('🍯');
      addParticle(index, 'honeypot', { x: clickX, y: clickY });
      if (combo > 1) {
        addParticle(index, 'combo', { x: clickX, y: clickY - 30, text: `${combo}x` });
      }

      // Cell state updates
      setDisabledCells(current => {
        const newDisabled = [...current];
        newDisabled[index] = true;
        return newDisabled;
      });
      
      setDiscovered(current => {
        const newDiscovered = [...current];
        newDiscovered[index] = cell;
        return newDiscovered;
      });

      // Check achievements
      if (!achievements.FIRST_BLOOD) {
        unlockAchievement('FIRST_BLOOD');
      }
      if (combo === MAX_COMBO) {
        unlockAchievement('COMBO_MASTER');
      }

      resetCell(index, HONEY_RESET_TIME);
    } 
    // Handle evil honeypot
    else if (cell === '😈') {
      if (powerups.active?.emoji === '🛡️') {
        addCursorEffect('🛡️');
        addParticle(index, 'powerup', { x: clickX, y: clickY });
        setPowerups(current => ({ ...current, active: null }));
        return;
      }

      addCursorEffect('😈');
      setScore(s => Math.max(0, s - 10));
      addParticle(index, 'evil', { x: clickX, y: clickY });
      setCombo(1); // Reset combo on evil pot
      
      setDisabledCells(current => {
        const newDisabled = [...current];
        newDisabled[index] = true;
        return newDisabled;
      });
      
      setDiscovered(current => {
        const newDiscovered = [...current];
        newDiscovered[index] = cell;
        return newDiscovered;
      });

      resetCell(index, EVIL_RESET_TIME);
    }
  }, [gameActive, disabledCells, cells, powerups, combo, lastSuccessTime, achievements, addParticle, resetCell, unlockAchievement]);

  // Game timer effect
  useEffect(() => {
    if (!gameActive) return;

    const timer = setInterval(() => {
      setTimeLeft(time => {
        if (time <= 1) {
          setGameActive(false);
          setHighScores(current => {
            const previousScores = Array.isArray(current[mode]) 
              ? current[mode] 
              : [current[mode] || []]; // Handle number or undefined
            const newHighScores = {
              ...current,
              [mode]: [score, ...previousScores].slice(0, 5), // Keep only last 5 scores
            };
            return newHighScores;
          });
          clearInterval(timer);
          return 0;
        }
        return time - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [gameActive, score, mode]);

  // Honeypot spawning effect
  useEffect(() => {
    if (!gameActive) return;

    const spawnTimer = setInterval(spawnHoneypot, SPAWN_INTERVAL);
    return () => clearInterval(spawnTimer);
  }, [gameActive, spawnHoneypot]);

  // Power-up duration effect
  useEffect(() => {
    if (powerups.active?.emoji === '✨') {
      const timer = setTimeout(() => {
        setPowerups(current => ({ ...current, active: null }));
      }, POWERUP_DURATION);
      return () => clearInterval(timer);
    }
  }, [powerups.active]);

  return (
    <div className="min-h-screen font-['Rajdhani'] bg-gradient-to-b from-black via-gray-900 to-black text-white">
      {/* Navigation */}
      <nav className="fixed w-full z-50 backdrop-blur-md bg-opacity-70">
        <div className="container mx-auto px-6 py-4 flex justify-between items-center">
          <Link to="/">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="text-2xl font-bold text-amber-400"
            >
              🍯 $HONEY
            </motion.div>
          </Link>
          <div className="flex items-center">
            <button
              onClick={() => setShowInstructions(true)}
              className="text-amber-400 hover:text-amber-300 transition-colors"
            >
              How to Play
            </button>
            <button
              onClick={() => setShowAchievementsModal(true)}
              className="text-amber-400 hover:text-amber-300 transition-colors ml-4"
            >
              Achievements
            </button>
            <button
              onClick={() => setShowHighScoresModal(true)}
              className="text-amber-400 hover:text-amber-300 transition-colors ml-4"
            >
              High Scores
            </button>
            {/* Removed Exit button from navigation */}
          </div>
        </div>
      </nav>

      {/* Floating Hammers */}
      <FloatingHammers onHammerClick={handleHammerClick} />

      {/* Fixed container for Particle Effects and Cursor Effects */}
      <div className="fixed inset-0 pointer-events-none z-50">
        {/* Particle Effects */}
        {particles.map(particle => (
          <ParticleEffect
            key={particle.id}
            x={particle.x}
            y={particle.y}
            type={particle.type}
            text={particle.text}
          />
        ))}

        {/* Cursor Effects */}
        <AnimatePresence>
          {cursorEffects.map(effect => (
            <CursorEffect
              key={effect.id}
              emoji={effect.emoji}
              x={effect.x}
              y={effect.y}
            />
          ))}
        </AnimatePresence>

        {/* Achievement Notification */}
        <AnimatePresence>
          {showAchievement && (
            <AchievementNotification achievement={showAchievement} />
          )}
        </AnimatePresence>

        {/* Error Message */}
        <AnimatePresence>
          {errorMessage && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className="fixed top-20 left-1/2 transform -translate-x-1/2 
                bg-red-500 text-white px-4 py-2 rounded-lg z-50"
            >
              {errorMessage}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Instructions Modal */}
      <AnimatePresence>
        {showInstructions && (
          <InstructionsModal onClose={() => setShowInstructions(false)} />
        )}
      </AnimatePresence>

      {/* Achievements Modal */}
      <AnimatePresence>
        {showAchievementsModal && (
          <AchievementsModal 
            achievements={achievements} 
            onClose={() => setShowAchievementsModal(false)} 
          />
        )}
      </AnimatePresence>

      {/* High Scores Modal */}
      <AnimatePresence>
        {showHighScoresModal && (
          <HighScoresModal 
            highScores={highScores} 
            onClose={() => setShowHighScoresModal(false)} 
          />
        )}
      </AnimatePresence>

      {/* Game Content */}
      <div className="container mx-auto pt-24 px-6 flex items-center justify-center min-h-screen">
        <div className="p-6 bg-gray-800/50 backdrop-blur-lg rounded-2xl shadow-xl border-2 border-amber-400/20">
          <div className="text-center mb-4">
            <h1 className="text-4xl font-bold mb-2 text-amber-400">Honeypot Digger</h1>
            
            {/* Mode Selection Screen */}
            {!gameActive && !mode && (
              <div className="space-y-4">
                <h2 className="text-2xl text-amber-400 mb-2">Select Game Mode</h2>
                <div className="grid gap-4">
                  {Object.entries(MODES).map(([modeKey, modeData]) => (
                    <motion.button
                      key={modeKey}
                      onClick={() => startGame(modeKey)}
                      className="w-full py-3 px-4 bg-gray-700/50 hover:bg-amber-400 
                        text-amber-400 hover:text-gray-900 rounded-xl text-xl font-bold
                        transition-colors duration-200 flex flex-col items-center gap-1"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <span>{modeData.name}</span>
                      <span className="text-sm opacity-75">{modeData.description}</span>
                      {highScores[modeKey]?.[0] > 0 && (
                        <span className="text-sm">Last Score: {highScores[modeKey][0]}</span>
                      )}
                    </motion.button>
                  ))}
                </div>
              </div>
            )}

            {/* Active Game UI */}
            {gameActive && (
              <>
                <div className="flex justify-between items-center mb-2">
                  <div className="text-2xl">Score: {score}</div>
                  <div className="text-2xl">Time: {timeLeft}s</div>
                </div>

                {combo > 1 && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    className="text-xl mb-2"
                  >
                    Combo: x{combo}
                  </motion.div>
                )}

                {powerups.active && (
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    className="text-xl mb-2 flex items-center justify-center gap-2"
                  >
                    <span>Active:</span>
                    <span className="bg-gray-700/50 px-3 py-1 rounded-full">
                      {powerups.active.emoji} {powerups.active.description}
                    </span>
                  </motion.div>
                )}

                <div className="grid grid-cols-3 gap-1 mb-4">
                  {cells.map((cell, index) => (
                    <Cell
                      key={index}
                      data-index={index}
                      index={index}
                      onDig={handleDig}
                      isDisabled={disabledCells[index]}
                      discovered={discovered[index]}
                      powerup={powerups.cells[index]}
                    />
                  ))}
                </div>

                {/* Item Legend */}
                <div className="mt-2 text-center text-gray-300 space-y-2">
                  <div className="flex justify-center gap-2 flex-wrap">
                    <span className="bg-gray-700/50 px-2 py-1 rounded-full">
                      🍯 +10 points
                    </span>
                    <span className="bg-gray-700/50 px-2 py-1 rounded-full">
                      😈 -10 points
                    </span>
                    <span className="bg-gray-700/50 px-2 py-1 rounded-full">
                      👑 +50 points
                    </span>
                    <span className="bg-gray-700/50 px-2 py-1 rounded-full">
                      💣 Bomb
                    </span>
                    <span className="bg-gray-700/50 px-2 py-1 rounded-full">
                      🌀 Portal
                    </span>
                  </div>
                </div>

                {/* Exit Button at the Bottom */}
                <motion.button
                  className="mt-4 w-full py-2 bg-gray-700/50 text-amber-400 rounded-xl 
                    text-xl font-bold hover:bg-gray-600/50 transition-colors duration-200"
                  onClick={() => {
                    setGameActive(false);
                    setMode(null);
                  }}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Exit
                </motion.button>
              </>
            )}

            {/* Game Over Screen */}
            {!gameActive && mode && (
              <div className="space-y-4">
                <div className="text-xl mb-2">
                  Final Score: {score}
                </div>
                <motion.button
                  className="w-full py-3 px-4 bg-amber-400 text-gray-900 rounded-xl 
                    text-xl font-bold hover:bg-amber-500 transition-colors duration-200"
                  onClick={() => startGame(mode)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Play Again
                </motion.button>
                <motion.button
                  className="w-full py-3 px-4 bg-gray-700/50 text-amber-400 rounded-xl 
                  text-xl font-bold hover:bg-gray-600/50 transition-colors duration-200"
                  onClick={() => setMode(null)}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Change Mode
                </motion.button>

                {/* Achievement Summary */}
                <div className="mt-4 pt-4 border-t border-gray-700">
                  <h3 className="text-xl font-bold mb-2">Achievements</h3>
                  <div className="grid gap-2">
                    {Object.entries(ACHIEVEMENTS).map(([id, achievement]) => {
                      const isUnlocked = achievements[id];
                      return (
                        <div 
                          key={id} 
                          className={`p-2 rounded-lg ${
                            isUnlocked 
                              ? 'bg-amber-400/20 text-amber-400' 
                              : 'bg-gray-700/50 text-gray-400'
                          }`}
                        >
                          <div className="flex justify-between items-center">
                            <span>{achievement.name}</span>
                            <span>{isUnlocked ? '🏆' : '🔒'}</span>
                          </div>
                          <div className="text-sm opacity-75">
                            {achievement.description}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Helper function to get neighboring cell indices
const getNeighborIndices = (index) => {
  const row = Math.floor(index / GRID_SIZE);
  const col = index % GRID_SIZE;
  const neighbors = [];

  // Check all 8 directions
  const directions = [
    [-1, -1], [-1, 0], [-1, 1],
    [ 0, -1],          [ 0, 1],
    [ 1, -1], [ 1, 0], [ 1, 1]
  ];

  for (const [dx, dy] of directions) {
    const newRow = row + dx;
    const newCol = col + dy;
    if (newRow >= 0 && newRow < GRID_SIZE && newCol >= 0 && newCol < GRID_SIZE) {
      neighbors.push(newRow * GRID_SIZE + newCol);
    }
  }

  return neighbors;
};

export default Game;
